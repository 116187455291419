import { get, post } from '../request'
/*积分模块 */
export default {

    /**
     * @description 积分添加 
     * @param {String} params 
     * @returns Object
     */
    postIntegralShopAdd(params) {
        return post(`/pointsExchangeItems/add`, params)
    },

    /**
    * @description 积分查询所有 列表
    * @param {String} params 
    * @returns Object
    */
    getIntegralShopSelectAll(params) {
        return get(`/pointsExchangeItems/selectAll`, params, true)
    },
    /**
     * @description 积分删除byid
     * @param {String} params 
     * @returns Object
     */
    // getGiftDelById(params) {
    //     return get(`/gift/delete/${params}`)
    // },

    /**
        * @description 更新积分商品修改
        * @param {String} params 
        * @returns Object
        */
    postIntegralShopUpdate(params) {
        return post(`/pointsExchangeItems/update`, params)
    },

    /**
      * @description 查询积分商品详情
      * @param {String} params 
      * @returns Object
      */
    getIntegralShopSelectToAH(params) {
        return get(`/pointsExchangeItems/selectById?id=${params}`)
    },
    /**
     * @description 查询积分商品订单记录
     * @param {String} params 
     * @returns Object
     */
    getIntegralShopOrderList(params) {
        return get(`/pointsExchangeOrder/selectAll`,params,true)
    },
}